import React from 'react'
import Layout from '../components/layout'
import BackgroundVideo from '../components/backgroundVideo'
import styles from './styles.module.css'
import SEO from '../components/seo'

export default () => {
    return (
        <Layout>
            <SEO 
                title='about' 
                description="🙋‍♂️ Hello! I'm just a 26-year-old guy who loves games and game-dev."
                pathname='/about/'
            />
            <BackgroundVideo initClip='Animals'/>
            <section className={styles.section}>
                <p className={styles.aboutNote} id='aboutNote'>
                    Sup! I'm Sap. A newbie solo indie making my <a href='https://outride.sappzo.com'>first game</a>. 
                    A ton of cool stuff is on the way so stick around!
                </p> 
            </section>
        </Layout>
    )
}  